<template>
  <component
    :is="renderedComponent"
    :title="post.title"
    :link="link"
    :thumbnail="post.thumbnail"
    :excerpt="post.lead"
    :author="post.author"
    :is-free="post.isFree"
    class="on-topic"
  />
</template>

<script>
import OnTopicComment from '~components/content/post-types/OnTopicComment.vue'
import OnTopicRegular from '~components/content/post-types/OnTopicRegular.vue'
import OnTopicNoPicture from '~components/content/post-types/OnTopicNoPicture.vue'
import { undomainify } from '~plugins/utils'

export default {
  components: {
    OnTopicComment, // eslint-disable-line vue/no-unused-components
    OnTopicRegular, // eslint-disable-line vue/no-unused-components
    OnTopicNoPicture, // eslint-disable-line vue/no-unused-components
  },
  props: {
    post: {
      type: Object,
      required: true,
    },
  },
  computed: {
    link() {
      return undomainify(this.post.link)
    },
    renderedComponent() {
      return this.post.isComment
        ? 'OnTopicComment'
        : this.post.thumbnail
        ? 'OnTopicRegular'
        : 'OnTopicNoPicture'
    },
  },
}
</script>
