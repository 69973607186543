<template>
  <div class="current-date">{{ currentDate }}</div>
</template>

<script>
import { format } from 'date-fns'
import deLocale from 'date-fns/locale/de'

export default {
  props: {
    format: {
      type: String,
      default() {
        return 'eee, d. MMM'
      },
    },
  },
  data() {
    return {
      currentDate: undefined,
    }
  },
  beforeMount() {
    this.updateDate()
  },
  mounted() {
    const interval = window.setInterval(() => {
      this.updateDate()
    }, 1000)

    this.$once('hook:beforeDestroy', () => {
      window.clearInterval(interval)
    })
  },
  methods: {
    updateDate() {
      this.currentDate = format(new Date(), this.format, {
        locale: deLocale,
      })
    },
  },
}
</script>
<style>
@import '~components/header/CurrentDate.css';
</style>
