


















































import { Vue, Component, Watch } from 'vue-property-decorator'
import Header from '~components/header/Header.vue'
import MaintenanceHeader from '~components/header/MaintenanceHeader.vue'
import JumpMarks from '~components/header/JumpMarks.vue'
import Footer from '~components/footer/Footer.vue'
import MaintenanceFooter from '~components/footer/MaintenanceFooter.vue'
import IEWarning from '~components/header/IEWarning.vue'
import GoogleAd from '~components/utils/GoogleAd.vue'
import { CookiebotConsent, ScriptsAfterGdprType } from '~base/common/types'

@Component({
  components: {
    Header,
    JumpMarks,
    Footer,
    IEWarning,
    MaintenanceHeader,
    MaintenanceFooter,
    GoogleAd,
  },
  head() {
    return {
      meta: [
        {
          name: 'apple-itunes-app',
          content: 'app-id=765344475',
        },
      ],
      script: [
        {
          src: 'https://consent.cookiebot.com/uc.js',
          'data-cbid': '5b61fb86-51ee-4c5e-a729-d6290ed2e13c',
          'data-framework': 'TCFv2.2',
          async: true,
        },
        {
          innerHTML: `
            if('serviceWorker'in navigator&&!('getRegistrations'in navigator.serviceWorker)){navigator.serviceWorker.getRegistrations=function(){return new Promise(function(resolve,reject){resolve([])})}}
          `,
        },
      ],
      __dangerouslyDisableSanitizers: ['script'],
    }
  },
})
export default class DefaultLayout extends Vue {
  scriptsAfterGdpr: ScriptsAfterGdprType = {
    necessary: [
      {
        src: this.$config.pianoSandbox
          ? `//sandbox.tinypass.com/api/tinypass.min.js`
          : `//experience.tinypass.com/xbuilder/experience/load?aid=${this.$config.pianoAppId}`,
        async: true,
      },
      {
        src: 'https://securepubads.g.doubleclick.net/tag/js/gpt.js',
        async: true,
      },
      {
        innerHTML: `
        window.googletag = window.googletag || { cmd: []}
        window.rmd_nativeslots = window.rmd_nativeslots || []

        window.googletag.cmd.push(function () {

          window.rmdMappingSkyscraper1 = googletag.sizeMapping().
            addSize([1024, 600], [[300, 600], [160, 600]]).
            addSize([0, 0], []).
            build();
          window.rmdMappingContentad1Mobile = googletag.sizeMapping().
            addSize([320,50], [[320, 250], [300, 250], [320, 50], [300, 50], 'fluid']).
            addSize([300,50], [[300, 250], [300, 50], 'fluid']).
            addSize([0, 0], []).
            build();

          window.googletag.pubads().enableSingleRequest()
          window.googletag.pubads().collapseEmptyDivs()
          window.googletag.pubads().disableInitialLoad()
          window.googletag.enableServices()
        })
        `,
      },
    ],
    statistics: [
      {
        innerHTML: `
      (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
      'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer','${this.$config.gtmTag}');
      `,
      },
      {
        innerHTML: `
      !function(f,b,e,v,n,t,s)
      {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
      n.callMethod.apply(n,arguments):n.queue.push(arguments)};
      if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
      n.queue=[];t=b.createElement(e);t.async=!0;
      t.src=v;s=b.getElementsByTagName(e)[0];
      s.parentNode.insertBefore(t,s)}(window, document,'script',
      'https://connect.facebook.net/en_US/fbevents.js');
      fbq('init', '298211937927823');
          `,
      },
      {
        innerHTML: `
          (function(p,l,o,w,i,n,g){if(!p[i]){p.GlobalSnowplowNamespace=p.GlobalSnowplowNamespace||[];
          p.GlobalSnowplowNamespace.push(i);p[i]=function(){(p[i].q=p[i].q||[]).push(arguments)};
          p[i].q=p[i].q||[];n=l.createElement(o);g=l.getElementsByTagName(o)[0];n.async=1;n.src=w;
          g.parentNode.insertBefore(n,g)}}
          (window,document,"script","https://www.vol.at/wp-content/static/vol/datateam/whitedragon.js","hansrmdt"));
          hansrmdt('newTracker', 'spdt', 'https://spdt.vol.at', {appId: 'neue-at-web', postPath: '/rmdtsp/tp2', contexts: {webPage: true, gaCookies: true, clientHints: true, session: false, performanceTiming: false}});
          $nuxt.$store.dispatch('tracking/sendLoginDataToSnowPlow')
        `,
      },
    ],
    marketing: [],
    preferences: [],
  }

  get isHomepage() {
    return this.$route.path === '/'
  }

  get isInMaintenanceMode(): boolean {
    return this.$config.inMaintenanceMode
  }

  get gdprSettings(): CookiebotConsent {
    return this.$store.getters['gdpr/consent']
  }

  mounted(): void {
    this.handleGdpr()
    if (this.$store.getters['app/isApp']) {
      this.$store.dispatch('app/autoLogin')
    }
  }

  handleGdpr(
    newVal: CookiebotConsent | undefined = undefined,
    oldVal: CookiebotConsent | undefined = undefined
  ): void {
    const difference = (
      o1: CookiebotConsent,
      o2: CookiebotConsent
    ): CookiebotConsent =>
      Object.entries(o2)
        .filter(
          ([key, value]) =>
            o1[key as keyof CookiebotConsent] !== value && key in o1
        )
        .reduce(
          (o, [key, value]) => ({ ...o, [key]: value }),
          {} as CookiebotConsent
        )

    let keys = Object.keys(this.gdprSettings)
    if (newVal && oldVal) {
      const changedValues = difference(oldVal, newVal)

      keys = Object.keys(changedValues)
    }
    keys.forEach((key) => {
      if (this.gdprSettings[key as keyof CookiebotConsent]) {
        if (
          typeof this.scriptsAfterGdpr[key as keyof CookiebotConsent] ===
          'undefined'
        )
          return
        this.scriptsAfterGdpr[key as keyof CookiebotConsent].forEach((s) => {
          const script = document.createElement(`script`)
          script.dataset.gdprCategory = key
          // script = { ...script, ...s }
          if (s.src) script.src = s.src
          if (s.async) script.async = s.async
          if (s.defer) script.defer = s.defer
          if (s.innerHTML) script.innerHTML = s.innerHTML
          if (s.type) script.type = s.type
          if (s.json) script.innerHTML = JSON.stringify(s.json)
          document.body.appendChild(script)
        })
      }
    })
  }

  @Watch('gdprSettings')
  onGdprSettingsChanged(newVal: CookiebotConsent, oldVal: CookiebotConsent) {
    this.handleGdpr(newVal, oldVal)
  }
}
