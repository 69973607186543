






















import { Vue, Component } from 'vue-property-decorator'
import MenuItem from '~components/header/MenuItem.vue'
import MenuButton from '~components/header/MenuButton.vue'
import { Menu, MenuItem as IMenuItem } from '~base/common/types'
import { flatListToHierarchical } from '~plugins/utils'

@Component<Nav>({
  components: {
    MenuItem,
    MenuButton,
  },
  apollo: {
    menu: {
      query: require('~base/graphql/queries/GetMenuByName.gql'),
      variables: {
        id: 'Header',
      },
      result({ data }) {
        try {
          this.menuItems = flatListToHierarchical(data.menu.menuItems.nodes)
        } catch (e) {
          console.error(e)
        }
      },
    },
  },
})
export default class Nav extends Vue {
  private menu: Menu = { menuItems: { nodes: [] } }
  private menuItems: IMenuItem[] = []
}
