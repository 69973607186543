import {
  enhance as baseEnhance,
  hasPicture as baseHasPicture,
  normalizeBlocks as baseNormalizeBlocks,
  generatePermalink,
  getDescription,
} from '../../base/plugins/utils'
import {
  Post,
  EnhancedPost,
  PostsInLayout,
  Layout,
  OpenGraphData,
  VueMetaProperty,
  Page,
} from '../common/types'

export {
  undomainify,
  generateLdPlusJson,
  flatListToHierarchical,
} from '../../base/plugins/utils'

export const enhance = (
  p: Post | EnhancedPost,
  settings: any
): EnhancedPost | undefined => {
  if (!p) return
  const workingPost = <EnhancedPost>baseEnhance(p, settings)
  if (!workingPost) return
  workingPost.ontopic = p.ontopic
  workingPost.link = generatePermalink(workingPost, 'neue')
  workingPost.permalink = `${settings.publicUrl}${workingPost.link}`
  return workingPost
}

export const normalizeBlocks = baseNormalizeBlocks

export const getRenderComponent = (post: EnhancedPost): string => {
  if (post.isComment) {
    return 'Comment'
  }
  if (post.ontopic) {
    return 'Topic'
  } else if (!post.hasPicture) {
    return 'NoPicture'
  }
  return 'Regular'
}

export const hasPicture = baseHasPicture

export const getGroupedPosts = (
  posts: EnhancedPost[],
  layouts: Layout[]
): PostsInLayout[] => {
  let availableLayouts = layouts.filter((l) => l.items > 0)

  let deferredPosts: Post[] = []
  const postsInLayouts: PostsInLayout[] = posts.reduce<PostsInLayout[]>(
    (grouped: PostsInLayout[], post: Post) => {
      // is the post a topic post? They need to be displayed in their own layout
      if (post.ontopic) {
        deferredPosts.push(post)
        return grouped
      }
      const currentGroup = grouped[grouped.length - 1]
      let layoutIdx = currentGroup.layout
      let currentLayout = availableLayouts[layoutIdx]
      if (currentGroup.posts.length === currentLayout.items) {
        // do we have deferredPosts?
        if (deferredPosts.length > 0) {
          deferredPosts.forEach((p: Post) => {
            const deferred = {
              layout: -1,
              component: () =>
                import('~components/content/layouts/TopicLayout.vue'),
              posts: [p],
            }

            grouped = [
              ...grouped.slice(0, grouped.length - 1),
              deferred,
              ...grouped.slice(grouped.length - 1),
            ]
          })
          deferredPosts = []
        }

        layoutIdx++
        if (layoutIdx > availableLayouts.length - 1) {
          layoutIdx = 0
          availableLayouts = layouts.filter((item) => !item.skipOnRepeat)
        }
        currentLayout = availableLayouts[layoutIdx]
        grouped.push({
          layout: layoutIdx,
          component: currentLayout.component,
          posts: [],
        })
      }
      grouped[grouped.length - 1].posts.push(post)
      return grouped
    },
    [{ layout: 0, component: availableLayouts[0].component, posts: [] }]
  )
  return postsInLayouts
}

export const generateOpenGraphMetaForPage = (p: Page, settings: any) => {
  const ogData: OpenGraphData = {
    ogImage: `${settings.publicUrl}/sharing/default-sharing.png`,
    title: p.title,
    description:
      p.content.replace(/(<([^>]+)>)/gi, '').substring(0, 300) + '...',
    author: p.author?.node.name || 'Neue Redaktion',
    permalink: generatePermalink(p),
  }

  return getOpenGraphArray(ogData, settings)
}

export const generateOpenGraphMeta = (
  settings: any,
  p?: EnhancedPost
): VueMetaProperty[] => {
  let ogData: OpenGraphData
  ogData = {
    title: settings.siteName,
    permalink: settings.publicUrl!,
    description: settings.description,
    ogImage: `${settings.publicUrl}/sharing/default-sharing.png`,
    author: 'Neue Redaktion',
  }
  if (p) {
    ogData = {
      title: p.title,
      permalink: p.permalink,
      description: getDescription(p),
      ogImage: `${settings.publicUrl}/meta/og${p.link}.jpg`,
      author: p.author?.node.name || 'Neue Redaktion',
    }
  }
  return getOpenGraphArray(ogData, settings)
}

const getOpenGraphArray = (
  ogData: OpenGraphData,
  settings: any
): VueMetaProperty[] => {
  return [
    { hid: 'description', name: 'description', content: ogData.description },
    {
      hid: 'author',
      name: 'author',
      content: ogData.author,
    },
    // facebook
    {
      hid: 'og:site_name',
      property: 'og:site_name',
      content: settings.siteName,
    },
    { hid: 'og:image', property: 'og:image', content: ogData.ogImage },
    { hid: 'og:image:url', property: 'og:image:url', content: ogData.ogImage },
    {
      hid: 'og:image:secure_url',
      property: 'og:image:secure_url',
      content: ogData.ogImage,
    },
    { hid: 'og:image:type', property: 'og:image:type', content: 'image/jpeg' },
    { hid: 'og:image:width', property: 'og:image:width', content: '1200' },
    { hid: 'og:image:height', property: 'og:image:height', content: '630' },
    { hid: 'og:title', property: 'og:title', content: ogData.title },
    {
      hid: 'og:description',
      property: 'og:description',
      content: ogData.description,
    },
    { hid: 'og:url', property: 'og:url', content: ogData.permalink },
    { hid: 'og:type', property: 'og:type', content: 'article' },
    { hid: 'fb:app_id', property: 'fb:app_id', content: settings.fbAppId! },

    // twitter
    {
      hid: 'twitter:image',
      property: 'twitter:image',
      content: ogData.ogImage,
    },
    {
      hid: 'twitter:card',
      property: 'twitter:card',
      content: 'summary_large_image',
    },
    {
      hid: 'twitter:site',
      property: 'twitter:site',
      content: settings.twitterHandle!,
    },
    {
      hid: 'twitter:url',
      property: 'twitter:url',
      content: ogData.permalink,
    },
    {
      hid: 'twitter:title',
      property: 'twitter:title',
      content: ogData.title,
    },
  ]
}
