





















import { Component } from 'vue-property-decorator'
import PostType from '~components/content/post-types/PostType.vue'
import PostTitle from '~components/content/PostTitle.vue'

@Component({
  components: {
    PostTitle,
  },
})
export default class Comment extends PostType {
  get renderedTitle() {
    return `<span class="text-red">${this.post.author.name}:</span> ${this.post.title}`
  }

  get authorPicture(): string {
    const author = this.post.author
    return author.altPicture || author.avatar.url
  }

  get isAvatarPicture(): boolean {
    return this.post.author.altPicture === null
  }
}
