<template>
  <header class="header" role="banner" :class="{ 'header--sticky': scrolled }">
    <div class="header__line header__line--mobile-top">
      <div><WeatherButton /></div>
      <div>
        <LoginLogout :short="true" />
      </div>
    </div>

    <div class="header__line header__line--logo-line">
      <Logo class="header__line--logo" />
      <Nav />
      <div>
        <LoginLogout :short="true" />
      </div>
      <WeatherButton />
    </div>

    <div
      class="header__line header__line--categories"
      :class="{ 'header__line--search-form': showSearchForm }"
    >
      <Nav />
      <div class="header__line--actions">
        <SearchButton />
        <CurrentDate />
        <WeatherButton data-testid="weather-button-md" />
      </div>
    </div>
    <WeatherWidget v-if="$store.getters['weather/showWidget']"></WeatherWidget>
  </header>
</template>

<script>
import _debounce from 'lodash.debounce'

import SearchButton from '~components/header/SearchButton.vue'

import Nav from '~components/header/Nav.vue'
import LoginLogout from '~components/header/LoginLogout.vue'
import Logo from '~components/header/Logo.vue'
import WeatherButton from '~components/header/WeatherButton.vue'
import WeatherWidget from '~components/header/WeatherWidget.vue'
import CurrentDate from '~components/header/CurrentDate.vue'

export default {
  components: {
    Logo,
    SearchButton,
    Nav,
    LoginLogout,
    WeatherButton,
    WeatherWidget,
    CurrentDate,
  },
  data() {
    return {
      scrolled: false,
      showSearchForm: false,
    }
  },
  mounted() {
    this.stickier = window.addEventListener(
      'scroll',
      _debounce(() => {
        this.scrolled = window.scrollY > 40
      }),
      250,
      { maxWait: 500 }
    )
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.stickier)
  },
}
</script>

<style>
@import '~components/header/Header.css';
</style>
