<template>
  <article class="on-topic-comment">
    <div class="on-topic-comment__content">
      <div class="meta">
        <span>Kommentar</span>
        <Plus v-if="!isFree" />
      </div>
      <PostTitle :title="theTitle" :permalink="link" tag="h4" />
    </div>
    <div class="on-topic-comment__author">
      <div>
        <img
          :src="authorPicture"
          :alt="author.name"
          :class="{ 'is-avatar': isAvatarPicture }"
        />
      </div>
    </div>
  </article>
</template>

<script>
import PostTitle from '~components/content/PostTitle.vue'
export default {
  components: {
    PostTitle,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    excerpt: {
      type: String,
      default: undefined,
    },
    link: {
      type: String,
      required: true,
    },
    author: {
      type: Object,
      required: true,
    },
    isFree: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    theTitle() {
      return `<span class="text-red">${this.author.name}:</span> ${this.title}`
    },
    authorPicture() {
      const author = this.author
      return author.altPicture || author.avatar.url
    },

    isAvatarPicture() {
      return this.author.altPicture === null
    },
  },
}
</script>

<style>
@import '~components/content/post-types/OnTopicComment.css';
</style>
