<template>
  <div class="post-lead" v-html="text"></div>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      default() {
        return ''
      },
    },
  },
}
</script>

<style>
@import '~components/content/PostLead.css';
</style>
