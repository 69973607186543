




import { Component, Vue, Prop, Watch } from 'vue-property-decorator'

interface ExtWindow extends Window {
  [key: string]: any
}
declare let window: ExtWindow

@Component
export default class GoogleAd extends Vue {
  @Prop({ type: String, required: true }) readonly id!: string
  @Prop({ type: String, required: true }) readonly dfpSlot!: string
  @Prop({ type: Array, default: () => ['fluid'] }) readonly size!: string[]
  @Prop({ type: String, default: `` }) readonly sizeMapping!: string

  private show = false

  @Watch('$route')
  onRouteChanged(to: any, from: any) {
    if (to.fullPath === from.fullPath) {
      return
    }
    const keys = Object.keys
    const toQuery = to.query
    const fromQuery = from.query
    let changed = false
    if (to.path !== from.path) {
      changed = true
    } else {
      changed =
        keys(toQuery).length !== keys(fromQuery).length ||
        !keys(toQuery).every((k) => toQuery[k] === fromQuery[k])
    }
    if (changed) {
      this.updateAd()
    }
  }

  updateAd() {
    if (this.$isServer) return
    this.show = false
    this.$nextTick(this.showAd)
  }

  showAd() {
    this.show = true

    this.$nextTick(() => {
      const { id, dfpSlot, size, sizeMapping, getUriParams, $store } = this

      window.googletag = window.googletag || { cmd: [] }

      let gtAvailWidth: number | string
      let gtPageWidth: number | string
      const gtSiteWidth = 1280
      if (window.innerWidth) {
        gtPageWidth = window.innerWidth
      } else {
        gtPageWidth = document.body.clientWidth
      }
      gtAvailWidth = (gtPageWidth - gtSiteWidth) / 2
      if (gtAvailWidth >= 300) {
        gtAvailWidth = '300'
      } else if (gtAvailWidth >= 160) {
        gtAvailWidth = '160'
      } else {
        gtAvailWidth = 'small'
      }

      window.googletag.cmd.push(function () {
        const ad = window.googletag.defineSlot(dfpSlot, size, `div-${id}`)
        if (
          sizeMapping.length > 0 &&
          Object.prototype.hasOwnProperty.call(window, sizeMapping)
        ) {
          ad.defineSizeMapping(window[sizeMapping])
        }
        ad.addService(window.googletag.pubads())
        window.googletag.pubads().setTargeting('availWidth', `${gtAvailWidth}`)
        const { pageType, category, consent } =
          $store.getters['ads/adParameters']

        window.googletag.pubads().setTargeting('pageType', pageType)
        window.googletag.pubads().setTargeting('category', category)
        window.googletag
          .pubads()
          .setTargeting('consent', consent ? `true` : `false`)
        window.googletag.display(`div-${id}`)

        const adPreview = getUriParams().adpreview
        if (adPreview) {
          window.googletag.pubads().setTargeting('adpreview', `${adPreview}`)
        }

        window.googletag.pubads().refresh([ad], { changeCorrelator: false })
      })
    })
  }

  mounted() {
    this.showAd()
  }

  beforeDestroy() {
    window.googletag = window.googletag || { cmd: [] }
    window.googletag.cmd.push(() => {
      window.googletag.destroySlots()
    })
  }

  getUriParams(): { [key: string]: string } {
    const queryString: { [key: string]: string } = {}
    const query = window.location.search.substring(1)
    const keyValueArray = query.split('&')
    if (keyValueArray.length <= 0) return queryString
    for (let i = 0; i < keyValueArray.length; i++) {
      const keyValue = keyValueArray[i].split('=')
      const value = decodeURIComponent(keyValue[1])
      if (value !== '' && keyValue[0] !== '') queryString[keyValue[0]] = value
    }
    return queryString
  }
}
