window.$OneSignal = window.OneSignal = window.OneSignal || [];

OneSignal.push(['init', {
  "appId": "326ec60d-8b22-4da3-b484-35070550c06a",
  "safari_web_id": "web.onesignal.auto.0818a4e7-118f-4fc1-b0e2-07892e811a2a",
  "allowLocalhostAsSecureOrigin": true,
  "welcomeNotification": {
    "disable": true
  }
}]);

export default function (ctx, inject) {
  inject('OneSignal', OneSignal)
}
