<template>
  <article class="on-topic-no-picture">
    <div class="content">
      <PostTitle :title="title" :permalink="link" tag="h4" />
    </div>
  </article>
</template>

<script>
import PostTitle from '~components/content/PostTitle.vue'
export default {
  components: {
    PostTitle,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    excerpt: {
      type: String,
      default: undefined,
    },
    link: {
      type: String,
      required: true,
    },
    isFree: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style>
@import '~components/content/post-types/OnTopicNoPicture.css';
</style>
