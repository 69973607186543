import merge from 'lodash.merge'
import lang from '../../base/lang/de'

const neue = {
  menu: {
    search: {
      buttontext: 'Suche',
    },
    register: 'Registrieren',
    logout: 'Abmelden',
  },
  epaper: {
    description: "Hier geht's zum E-Paper der NEUE",
  },
  footer: {
    copyright: ' · NEUE Vorarlberger Tageszeitung',
  },
  seo: {
    title: {
      category: {
        default: 'Aktuelle Nachrichten auf NEUE.at',
      },
      tags: {
        default: 'Aktuelle Nachrichten auf NEUE.at',
      },
      search: 'Aktuelle Nachrichten auf NEUE.at',
      all: 'Alle Kategorien auf NEUE.at',
      author: 'Artikel von {0} auf NEUE.at',
      mitmachen: `NEUE.at Gewinnspiel - Mitmachen und gewinnen`,
      tipp: `Tipp an die NEUE Redaktion`,
    },
    description: {
      category:
        'Alle aktuellen News aus dem Ressort {0} schnell und übersichtlich auf NEUE.at. Hier verpassen Sie keine {0}-Nachrichten.',
      tags: 'Alle aktuellen News zu #{0} schnell und übersichtlich auf NEUE.at. Hier verpassen Sie keine Nachrichten über #{0}.',
      search:
        'Alle aktuellen News zu „{0}“ schnell und übersichtlich auf NEUE.at. Hier verpassen Sie keine Nachrichten über „{0}“.',
      author:
        'Alle Artikel von {0} schnell und übersichtlich auf NEUE.at. Hier verpassen Sie keine Artikel von {0}.',
      mitmachen: `Immer wieder tolle Gewinnspiele auf NEUE.at`,
      tipp: `Tipp an die NEUE Redaktion senden`,
    },
  },
  search: {
    label: 'Begriff eingeben und Suche starten',
    placeholder: 'Suchen',
    submit: 'Suchen',
    noEntries: 'Es wurden leider keine Einträge gefunden.',
  },
  weather: {
    today: 'Heute <span class="text-blue-500">in</span>',
    'current-temp': 'Aktuelle Temperatur',
  },
  'author-page': {
    contact: 'Kontakt',
    'mail-subject': 'Kontakt über neue.at',
    'all-content': 'Alle Artikel von {name}',
    'all-content-count': '{count} Artikel',
  },
  paywall: {
    header: {
      title: 'NEUE Geschichten. NEUE Einblicke. NEUE lesen.',
    },
    register: {
      legal:
        'Es gelten die <a target="_blank" href= "/agb">AGB</a> und <a target="_blank" href="/datenschutzerklaerung">Datenschutzbestimmungen</a> der NEUE Zeitungs GmbH.',
      'to-upsell': 'Lesen Sie die gedruckte Zeitung? <b>Zum Aboshop</b>',
      'to-login': 'Sie haben bereits die NEUE-Digital? Hier anmelden',
    },
  },
  app: {
    onboarding: {
      calltoaction: 'Jetzt freischalten und weiterlesen.',
      title: 'Das Digitalpaket der NEUE Vorerlberger Tageszeitung',
      features:
        '<li>Lokaler Journalismus mit Engagement, unabhängig von der Tageszeit</li><li>Reportagen, Hintergründe, Kommentare und Schwerpunktberichterstattungen</li><li>Animierte Grafiken, Bilderserien und Videos</li>',
      loginbuttontext: 'Login als NEUE-Kunde',
      registerbuttontext: 'Neu registrieren',
      pricinginformation: '',
    },
    upsell: {
      info: `Sichern Sie sich als Leser der gedruckten Zeitung unser Vorteilsangebot.
      Lesen Sie alle Neuigkeiten auch auf unserem
      <b>Premium-Newsportal NEUE.at</b> und greifen Sie auf das
      <b>E-Paper</b> zu.`,
    },
    offer: {
      title: 'Angebot wählen:',
    },
  },
  mitmachen: {
    title: 'Mitmachen und gewinnen',
    catchphrase: 'Immer wieder tolle Gewinnspiele auf NEUE.at',
    subscriber: 'Sind Sie bereits NEUE Abonnent?',
    abono: `NEUE Abonummer`,
    orderonlinetest: `Ja, ich bestelle die <strong>NEUE-Ditigal</strong> für 4 Wochen gratis.<br/>Das Abo endet automatisch.`,
    orderprinttest: `Ja, ich bestelle die <strong>gedruckte NEUE</strong> für 4 Wochen gratis.<br/>Das Abo endet automatisch.`,
    newsletter: `NEUE.at-Morgennewsletter abonnieren`,
    success: `Herzlichen Dank für Ihre Teilnahme am NEUE.at-Gewinnspiel und viel Glück!`,
    offers: `Ja, ich möchte von Vorteilsangeboten profitieren und bin damit einverstanden, dass die NEUE Zeitungs GmbH meine Daten (= Name, Adresse, E-Mail-Adresse und Telefonnummer) zwecks Kontaktaufnahme per Brief, Telefon, Messenger Dienst oder E-Mail zur Information über Produkte und aktuelle Angebote der Russmedia-Gruppe verarbeitet sowie zu diesem Zweck an <a href="https://www.neue.at/unternehmen-der-russmedia-gruppe" target="_blank">Unternehmen der Russmedia-Gruppe</a> weitergibt. Diese Einwilligung kann ich jederzeit widerrufen.
    <br>Weitere Informationen unter: <a href="https://neue.at/datenschutzerklaerung" target="_blank">Datenschutzinformationen</a>`,
    privacy: ` Ich akzeptiere die
    <a href="https://www.russmedia.com/russmedia-gruppe-teilnahmebedingungen/" target="_blank">Teilnahmebedingungen</a> und <a href="https://www.neue.at/agb" target="_blank">AGBs</a> der NEUE Zeitungs GmbH.`,
  },
  aboshopWidget: {
    buttonText: 'Zum NEUE-Aboshop',
  },
}

export default merge(lang, neue)
