// register sidebar components, add sidebar config
import Vue from 'vue'
import NewsTicker from '~components/content/NewsTicker.vue'

Vue.component('NewsTicker', NewsTicker)

export const sidebarConfig = [
  {
    component: 'NewsTicker',
    props: { class: 'md:hidden', amount: 10 },
    feedPosition: 2,
  },
]

export function hasWidgetAtPosition(position) {
  return sidebarConfig.find((sc) => sc.feedPosition === position)
}

export function getWidgetsAtPosition(position) {
  return sidebarConfig.filter((sc) => sc.feedPosition === position)
}

export function isSmallScreen() {
  if (process.server) return false
  return !window.matchMedia('(min-width: 768px)')
}
