import { getters as baseGetters } from '../../base/store/news'
export { state, mutations, actions } from '../../base/store/news'

const gartenPageSettings = {
  headerComponent: `Garten`,
  headerComponentInArticle: true,
  pageClass: `garten-page`,
  sidebar: `garten`,
  head: {
    link: [
      {
        href: 'https://fonts.googleapis.com/css2?family=Roboto:wght@300;900&display=swap',
        rel: 'stylesheet',
      },
    ],
  },
  layouts: [
    {
      component: () =>
        import(
          /* webpackChunkName: "garten-layout" */ `~components/content/layouts/GartenLayout-1.vue`
        ),
      items: 1,
    },
    {
      component: () =>
        import(
          /* webpackChunkName: "garten-layout" */ `~components/content/layouts/GartenLayout-2.vue`
        ),
      items: 2,
    },
  ],
}

export const getters = {
  ...baseGetters,
  tagsSettings: () => ({
    gartenlust: gartenPageSettings,
    gartentipp: gartenPageSettings,
  }),
  categorySettings: () => ({
    flora: gartenPageSettings,
    allgemein: {
      sidebar: 'allgemein',
    },
  }),
}
