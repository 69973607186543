





































import { Component } from 'vue-property-decorator'
import PostType from '~components/content/post-types/PostType.vue'
import Regular from '~components/content/post-types/Regular.vue'
import OnTopic from '~components/content/post-types/OnTopic.vue'

@Component({
  components: {
    Regular,
    OnTopic,
  },
})
export default class Topic extends PostType {
  navigateToTopic() {
    this.$router.push(`/tags/${this.post.ontopic.slug}`)
  }
}
