<template>
  <article class="on-topic-regular clearfix">
    <LazyPicture :picture="thumbnail" :title="title" :is-free="isFree" />
    <div class="content">
      <PostTitle :title="title" :permalink="link" tag="h4" />
      <PostLead :text="excerpt"></PostLead>
    </div>
  </article>
</template>

<script>
import PostTitle from '~components/content/PostTitle.vue'
import LazyPicture from '~components/utils/LazyPicture.vue'
import PostLead from '~components/content/PostLead.vue'

export default {
  components: {
    PostTitle,
    LazyPicture,
    PostLead,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    excerpt: {
      type: String,
      default: undefined,
    },
    thumbnail: {
      type: Object,
      required: true,
    },
    link: {
      type: String,
      required: true,
    },
    isFree: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style>
@import '~components/content/post-types/OnTopicRegular.css';
</style>
