<template>
  <article class="news-ticker-article">
    <div class="meta news-ticker-article__date">
      {{ time }}
    </div>
    <PostTitle
      :title="post.title"
      :permalink="post.link || `/newsticker/${post.slug}/${post.postId}`"
    />
  </article>
</template>

<script>
import { parseISO, format } from 'date-fns'
import PostTitle from '~components/content/PostTitle.vue'

export default {
  components: {
    PostTitle,
  },
  props: {
    post: {
      type: Object,
      required: true,
    },
  },
  computed: {
    time() {
      return format(parseISO(`${this.post.dateGmt}+0`), 'HH:mm')
    },
  },
}
</script>

<style>
@import '~components/content/post-types/NewsTickerArticle.css';
</style>
