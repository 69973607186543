import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _2c2066a8 = () => interopDefault(import('../themes/base/pages/ressort/_category/index.vue' /* webpackChunkName: "" */))
const _4b525548 = () => interopDefault(import('../themes/neue/pages/menu.vue' /* webpackChunkName: "pages/menu" */))
const _64daf38d = () => interopDefault(import('../themes/neue/pages/mitmachen.vue' /* webpackChunkName: "pages/mitmachen" */))
const _066f99c0 = () => interopDefault(import('../themes/base/pages/offline.vue' /* webpackChunkName: "pages/offline" */))
const _ea0c5fd8 = () => interopDefault(import('../themes/neue/pages/suche/index.vue' /* webpackChunkName: "pages/suche/index" */))
const _ef898f9c = () => interopDefault(import('../themes/neue/pages/tipp.vue' /* webpackChunkName: "pages/tipp" */))
const _5714378f = () => interopDefault(import('../themes/base/pages/validate-password.vue' /* webpackChunkName: "pages/validate-password" */))
const _746cd2ce = () => interopDefault(import('../themes/base/pages/admin/debug-vars.vue' /* webpackChunkName: "pages/admin/debug-vars" */))
const _2ed0a99a = () => interopDefault(import('../themes/base/pages/admin/mitmachen/index.vue' /* webpackChunkName: "pages/admin/mitmachen/index" */))
const _4356eea5 = () => interopDefault(import('../themes/base/pages/admin/preview.vue' /* webpackChunkName: "pages/admin/preview" */))
const _e651d274 = () => interopDefault(import('../themes/base/pages/ads/300x250.vue' /* webpackChunkName: "pages/ads/300x250" */))
const _57e7c8af = () => interopDefault(import('../themes/base/pages/ads/300x600.vue' /* webpackChunkName: "pages/ads/300x600" */))
const _26aa0bdc = () => interopDefault(import('../themes/base/pages/ads/testads.vue' /* webpackChunkName: "pages/ads/testads" */))
const _0e8516d0 = () => interopDefault(import('../themes/base/pages/app/login.vue' /* webpackChunkName: "pages/app/login" */))
const _2c64892d = () => interopDefault(import('../themes/base/pages/ressort/all.vue' /* webpackChunkName: "pages/ressort/all" */))
const _e5f952f8 = () => interopDefault(import('../themes/base/pages/ressort/_category/page/_page.vue' /* webpackChunkName: "" */))
const _5b8997c8 = () => interopDefault(import('../themes/neue/pages/suche/page/_page.vue' /* webpackChunkName: "pages/suche/page/_page" */))
const _35990dbc = () => interopDefault(import('../themes/base/pages/author/_slug/index.vue' /* webpackChunkName: "pages/author/_slug/index" */))
const _0127b2a1 = () => interopDefault(import('../themes/base/pages/page/_page.vue' /* webpackChunkName: "pages/page/_page" */))
const _c02cb29e = () => interopDefault(import('../themes/base/pages/tags/_tag/index.vue' /* webpackChunkName: "pages/tags/_tag/index" */))
const _29483f74 = () => interopDefault(import('../themes/base/pages/author/_slug/page/_page.vue' /* webpackChunkName: "pages/author/_slug/page/_page" */))
const _98ac10c2 = () => interopDefault(import('../themes/base/pages/tags/_tag/page/_page.vue' /* webpackChunkName: "pages/tags/_tag/page/_page" */))
const _0d062ab2 = () => interopDefault(import('../themes/base/pages/author/_slug/_id/index.vue' /* webpackChunkName: "pages/author/_slug/_id/index" */))
const _4cef3de5 = () => interopDefault(import('../themes/base/pages/newsticker/_post_slug/_id.vue' /* webpackChunkName: "pages/newsticker/_post_slug/_id" */))
const _5670bd69 = () => interopDefault(import('../themes/base/pages/author/_slug/_id/page/_page.vue' /* webpackChunkName: "pages/author/_slug/_id/page/_page" */))
const _029f926f = () => interopDefault(import('../themes/base/pages/index.vue' /* webpackChunkName: "pages/index" */))
const _e2b68372 = () => interopDefault(import('../themes/base/pages/_cat_or_page_slug/index.vue' /* webpackChunkName: "pages/_cat_or_page_slug/index" */))
const _9c748100 = () => interopDefault(import('../themes/base/pages/_cat_or_page_slug/_year/_month/_day/_post_slug/index.vue' /* webpackChunkName: "pages/_cat_or_page_slug/_year/_month/_day/_post_slug/index" */))
const _a7f2d61c = () => interopDefault(import('../themes/base/pages/_cat_or_page_slug/_year/_month/_day/_post_slug/amp.vue' /* webpackChunkName: "pages/_cat_or_page_slug/_year/_month/_day/_post_slug/amp" */))
const _4af1850a = () => interopDefault(import('../themes/base/pages/_cat_or_page_slug/_year/_month/_day/_post_slug/sharing.vue' /* webpackChunkName: "pages/_cat_or_page_slug/_year/_month/_day/_post_slug/sharing" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/flora",
    component: _2c2066a8,
    props: {"category":"flora"},
    name: "flora"
  }, {
    path: "/international",
    component: _2c2066a8,
    props: {"category":"international"},
    name: "international"
  }, {
    path: "/karriere",
    component: _2c2066a8,
    props: {"category":"karriere"},
    name: "karriere"
  }, {
    path: "/kultur",
    component: _2c2066a8,
    props: {"category":"kultur"},
    name: "kultur"
  }, {
    path: "/lokal",
    component: _2c2066a8,
    props: {"category":"lokal"},
    name: "lokal"
  }, {
    path: "/menu",
    component: _4b525548,
    name: "menu"
  }, {
    path: "/mitmachen",
    component: _64daf38d,
    name: "mitmachen"
  }, {
    path: "/oesterreich",
    component: _2c2066a8,
    props: {"category":"oesterreich"},
    name: "oesterreich"
  }, {
    path: "/offline",
    component: _066f99c0,
    name: "offline"
  }, {
    path: "/politik",
    component: _2c2066a8,
    props: {"category":"politik"},
    name: "politik"
  }, {
    path: "/sport",
    component: _2c2066a8,
    props: {"category":"sport"},
    name: "sport"
  }, {
    path: "/suche",
    component: _ea0c5fd8,
    name: "suche"
  }, {
    path: "/tipp",
    component: _ef898f9c,
    name: "tipp"
  }, {
    path: "/unfaelle",
    component: _2c2066a8,
    props: {"category":"unfaelle"},
    name: "unfaelle"
  }, {
    path: "/validate-password",
    component: _5714378f,
    name: "validate-password"
  }, {
    path: "/vorarlberg",
    component: _2c2066a8,
    props: {"category":"vorarlberg"},
    name: "vorarlberg"
  }, {
    path: "/wirtschaft",
    component: _2c2066a8,
    props: {"category":"wirtschaft"},
    name: "wirtschaft"
  }, {
    path: "/admin/debug-vars",
    component: _746cd2ce,
    name: "admin-debug-vars"
  }, {
    path: "/admin/mitmachen",
    component: _2ed0a99a,
    name: "admin-mitmachen"
  }, {
    path: "/admin/preview",
    component: _4356eea5,
    name: "admin-preview"
  }, {
    path: "/ads/300x250",
    component: _e651d274,
    name: "ads-300x250"
  }, {
    path: "/ads/300x600",
    component: _57e7c8af,
    name: "ads-300x600"
  }, {
    path: "/ads/testads",
    component: _26aa0bdc,
    name: "ads-testads"
  }, {
    path: "/app/login",
    component: _0e8516d0,
    name: "app-login"
  }, {
    path: "/ressort/all",
    component: _2c64892d,
    name: "ressort-all"
  }, {
    path: "/flora/page/:page?",
    component: _e5f952f8,
    props: {"category":"flora"},
    name: "flora-page-page"
  }, {
    path: "/international/page/:page?",
    component: _e5f952f8,
    props: {"category":"international"},
    name: "international-page-page"
  }, {
    path: "/karriere/page/:page?",
    component: _e5f952f8,
    props: {"category":"karriere"},
    name: "karriere-page-page"
  }, {
    path: "/kultur/page/:page?",
    component: _e5f952f8,
    props: {"category":"kultur"},
    name: "kultur-page-page"
  }, {
    path: "/lokal/page/:page?",
    component: _e5f952f8,
    props: {"category":"lokal"},
    name: "lokal-page-page"
  }, {
    path: "/oesterreich/page/:page?",
    component: _e5f952f8,
    props: {"category":"oesterreich"},
    name: "oesterreich-page-page"
  }, {
    path: "/politik/page/:page?",
    component: _e5f952f8,
    props: {"category":"politik"},
    name: "politik-page-page"
  }, {
    path: "/sport/page/:page?",
    component: _e5f952f8,
    props: {"category":"sport"},
    name: "sport-page-page"
  }, {
    path: "/suche/page/:page?",
    component: _5b8997c8,
    name: "suche-page-page"
  }, {
    path: "/unfaelle/page/:page?",
    component: _e5f952f8,
    props: {"category":"unfaelle"},
    name: "unfaelle-page-page"
  }, {
    path: "/vorarlberg/page/:page?",
    component: _e5f952f8,
    props: {"category":"vorarlberg"},
    name: "vorarlberg-page-page"
  }, {
    path: "/wirtschaft/page/:page?",
    component: _e5f952f8,
    props: {"category":"wirtschaft"},
    name: "wirtschaft-page-page"
  }, {
    path: "/author/:slug",
    component: _35990dbc,
    name: "author-slug"
  }, {
    path: "/page/:page?",
    component: _0127b2a1,
    name: "page-page"
  }, {
    path: "/ressort/:category",
    component: _2c2066a8,
    name: "ressort-category"
  }, {
    path: "/tags/:tag",
    component: _c02cb29e,
    name: "tags-tag"
  }, {
    path: "/author/:slug?/page/:page?",
    component: _29483f74,
    name: "author-slug-page-page"
  }, {
    path: "/ressort/:category?/page/:page?",
    component: _e5f952f8,
    name: "ressort-category-page-page"
  }, {
    path: "/tags/:tag?/page/:page?",
    component: _98ac10c2,
    name: "tags-tag-page-page"
  }, {
    path: "/author/:slug?/:id",
    component: _0d062ab2,
    name: "author-slug-id"
  }, {
    path: "/newsticker/:post_slug?/:id?",
    component: _4cef3de5,
    name: "newsticker-post_slug-id"
  }, {
    path: "/author/:slug?/:id/page/:page?",
    component: _5670bd69,
    name: "author-slug-id-page-page"
  }, {
    path: "/",
    component: _029f926f,
    name: "index"
  }, {
    path: "/:cat_or_page_slug",
    component: _e2b68372,
    name: "cat_or_page_slug"
  }, {
    path: "/:cat_or_page_slug/:year/:month?/:day?/:post_slug",
    component: _9c748100,
    name: "cat_or_page_slug-year-month-day-post_slug"
  }, {
    path: "/:cat_or_page_slug/:year/:month?/:day?/:post_slug?/amp",
    component: _a7f2d61c,
    name: "cat_or_page_slug-year-month-day-post_slug-amp"
  }, {
    path: "/:cat_or_page_slug/:year/:month?/:day?/:post_slug?/sharing",
    component: _4af1850a,
    name: "cat_or_page_slug-year-month-day-post_slug-sharing"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
