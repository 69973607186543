<template>
  <header class="header" role="banner">
    <div class="header__line header__line--logo-line">
      <Logo class="header__line--logo" />
    </div>
  </header>
</template>

<script>
import Logo from '~components/header/Logo.vue'

export default {
  components: {
    Logo,
  },
}
</script>

<style>
@import '~components/header/Header.css';
</style>
