































import { Component } from 'vue-property-decorator'
import PostType from '~components/content/post-types/PostType.vue'
import PostTitle from '~components/content/PostTitle.vue'
import PostMeta from '~components/content/PostMeta.vue'
import LazyPicture from '~components/utils/LazyPicture.vue'
import PostLead from '~components/content/PostLead.vue'

@Component({
  components: {
    PostTitle,
    LazyPicture,
    PostMeta,
    PostLead,
  },
})
export default class Regular extends PostType {}
