<template>
  <nuxt />
</template>

<script>
export default {
  head() {
    return {
      link: [
        {
          rel: 'preconnect',
          href: 'https://fonts.gstatic.com',
        },
        {
          href: 'https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@400;700&display=swap',
          rel: 'stylesheet',
        },
        {
          href: 'https://fonts.googleapis.com/css?family=Fira+Sans+Condensed:400,700&display=swap',
          rel: 'stylesheet',
        },
      ],
    }
  },
}
</script>
