













import { Component } from 'vue-property-decorator'
import BaseLoginLogout from '../../../base/components/header/LoginLogout.vue'
import { MenuItem as IMenuItem } from '~base/common/types'

@Component
export default class LoginLogout extends BaseLoginLogout {
  get register(): IMenuItem {
    return {
      id: '',
      parentId: '',
      label: this.$t('menu.register').toString(),
      cssClasses: ['login_logout__register'],
      click: () => {
        this.$store.dispatch('paywall/showRegistrationOverlay', {
          showCloseButton: true,
        })
      },
    }
  }

  get menuItem(): IMenuItem {
    if (!this.isLoggedIn) {
      return {
        id: '',
        parentId: '',
        label: this.$t('menu.login').toString(),
        cssClasses: ['login_logout__login'],
        click: () => {
          this.$store.dispatch('paywall/showLoginOverlay', {
            showCloseButton: true,
          })
        },
      }
    }
    return {
      childItems: [
        {
          id: 'loggedin-mydata',
          parentId: '',
          label: this.$t('menu.mydata').toString(),
          url: undefined,
          click: () => {
            this.$store.dispatch('paywall/showDataOverlay', {
              showCloseButton: true,
            })
          },
          cssClasses: [],
        },
        {
          id: 'loggedin-logout',
          parentId: '',
          label: this.$t('menu.logout').toString(),
          url: ``,
          click: () => {
            this.$store.dispatch('paywall/logout')
          },
          cssClasses: [`logout`],
        },
      ],
      id: 'loggedn-in-myaccount',
      parentId: '',
      cssClasses: ['my-account'],
      url: `#`,
      label:
        this.$store.getters['paywall/displayName'] ||
        this.$t('menu.myaccount').toString(),
    }
  }
}
